import { Box } from '@mui/material'
import { Typography } from 'antd'
import React from 'react'

const PageNotFound = () => {
  return (
    <Box
    height="90vh"
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    bgcolor="background.paper"
    color="text.primary"
    textAlign="center"
    p={3}
  >
    <Typography variant="h1" component="h1" gutterBottom>
      404
    </Typography>
    <Typography variant="h4" component="h2" gutterBottom>
      Page Not Found
    </Typography>
    <Typography variant="body1">
      Sorry, the page you are looking for does not exist.
    </Typography>
  </Box>
  )
}

export default PageNotFound